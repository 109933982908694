<template>
  <div class="brands-buttons">
    <div v-for="brand in brands" :key="brand" class="brands-buttons__item">
      <v-btn
        @click="$emit('clickedOnBrand', brand)"
        class="brands-buttons__btn"
        :class="{ active: selectedBrands.includes(brand) }"
        >{{ brand }}</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Brands",
  props: {
    brands: {
      type: Array,
      default: () => [],
    },
    selectedBrands: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.brands-buttons {
  width: 100%;
  max-width: 670px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 20px;
  @media screen and (max-width: 760px) {
    padding-top: 50px;
    max-width: 315px;
    justify-content: center;
  }
  &__item {
    margin: 0 5px;
    &:nth-child(5) {
      margin-left: 70px;
      @media screen and (max-width: 760px) {
        margin-left: 5px;
      }
    }
    &:nth-child(4) {
      @media screen and (max-width: 760px) {
        margin-left: 10px;
      }
    }
  }
  &__btn {
    width: 136px;
    height: 136px !important;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    box-shadow: none;
    background: #92c4ff !important;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-transform: none;
    letter-spacing: inherit;
    @media screen and (max-width: 760px) {
      width: 95px;
      height: 95px !important;
      font-size: 14px;
    }
    &.active {
      font-weight: 700;
      background: #f5ede2 !important;
    }
  }
}
</style>
