<template>
  <div class="smb-client-wrapper">
    <SmbTitles is-show-title is-show-sub-title title="Values" />
    <div class="form-container">
      <div class="form-container__title">
        Pick up to 3 values that are key to your brand
      </div>
      <div class="form-container__values">
        <Brands
          :brands="brandsList"
          :selected-brands="valuesList"
          @clickedOnBrand="clickedOnValueHandler($event)"
        />
      </div>
    </div>
    <div class="footer-buttons">
      <SmbActionBtn
        class="arrow-button"
        isUseIcon
        iconName="mdi-arrow-left"
        width="20%"
        @click="$router.push({ name: 'profile-settings' })"
      />
      <SmbActionBtn
        width="80%"
        :isLoading="isSaveBtnLoading"
        :text="isValuesChanged && valuesList.length <= 3 ? 'Save' : 'Cancel'"
        :emitName="
          isValuesChanged && valuesList.length <= 3 ? 'save' : 'cancel'
        "
        @cancel="$router.push({ name: 'profile-settings' })"
        @save="saveHandler"
      />
    </div>
  </div>
</template>

<script>
import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn.vue";
import Brands from "@/views/smb-client/pages/onboarding/steps/Brands.vue";
import { mapActions, mapGetters } from "vuex";
import H from "@/utils/helper";
export default {
  name: "Index",
  data: () => ({
    isSaveBtnLoading: false,
    isValuesChanged: false,
    valuesList: [],
    brandsList: [
      "Friendly",
      "Relaxed",
      "Professional",
      "Bold",
      "Persuasive",
      "Luxury",
      "Informative",
      "Witty",
    ],
  }),
  components: { SmbTitles, SmbActionBtn, Brands },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
  },
  created() {
    this.getCurrentSmbCustomer.brand_values.length
      ? (this.valuesList = this.getCurrentSmbCustomer.brand_values)
      : (this.valuesList = []);
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
    async saveHandler() {
      try {
        this.isSaveBtnLoading = true;
        await this.updateCustomer({
          customerId: this.getCurrentSmbCustomer.id,
          params: {
            brand_values: this.valuesList,
          },
        });
        await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
        this.isSaveBtnLoading = false;
        await this.$router.push({ name: "profile-settings" });
      } catch (e) {
        console.log(e);
      }
    },
    clickedOnValueHandler(val) {
      if (!val && !val.length) return;
      if (this.valuesList.includes(val)) {
        this.valuesList = this.valuesList.filter((v) => v !== val);
      } else if (this.valuesList.length < 3) {
        this.valuesList.push(val);
      }
      this.isValuesChanged = true;
    },
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f2f3;
  height: 100%;
  .form-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 15px;
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #1f2325;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &__values {
      max-width: 650px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep {
        .brands-buttons {
          padding-top: 30px;
          margin-bottom: 30px;
          &__btn {
            border: 1px solid #000;
            background: transparent !important;
            &:before {
              display: none;
            }
            &.active {
              background: #92c4ff !important;
            }
          }
        }
      }
    }
  }
  .footer-buttons {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: auto;
    letter-spacing: 0;
    position: relative;
    @media screen and (min-width: 760px) {
      margin-bottom: 20px;
    }

    .button-item {
      width: 100%;
      @media screen and (min-width: 760px) {
        width: 313px;
      }
    }
    .arrow-button {
      width: 20%;
      position: absolute;
      left: 0;
      z-index: 10;
      @media screen and (min-width: 760px) {
        display: none;
      }
    }
  }
}
</style>
